<template>
  <div class="withdrawal">
    <div class="tipsBox">
      <div class="tipsBox-title">
        请务必完善 <span>【线上签约】</span> 和 <span>【系统信息采集】</span>
      </div>
      <van-divider>
        线上签约
      </van-divider>
      <div class="tipsBox-content-list">
        <div class="tipsBox-conent-row mb-20">
          为保护合作双方的合法权益及合作收付款的安全性，达人需要完成线上签约。
        </div>
        <div class="tipsBox-conent-row">
          1、点击下方立即签约入口，使用与达人平台相同手机号注册签约
        </div>
        <div class="tipsBox-conent-row">
          2、签约需要填写与达人平台填写相同的身份证信息和收款信息。
        </div>
        <div class="tipsBox-conent-row">
          3、完成签约后才可以申请收益提现。
        </div>
        <div class="tipsBox-conent-row">
          4、如对签约流量有疑义，请联系达人<span @click="$router.push('/contact')" style="color:#75ccee;font-weight:bold">平台客服</span>
          沟通具体流程。
        </div>
      </div>
      <a class="tips-btn" href="https://s.letsign.com/Ape-kHnuiv1WGRkF/">立即签约</a>
    </div>
    <van-divider>
      系统信息采集
    </van-divider>
    <van-form @submit="onSubmit" ref="form">
      <van-field class="setBorder" v-model="form.name" :border="false" label="真实姓名：" placeholder="请填写您的姓名" :rules="[
          {
            required: true,
            message: '请填写您的姓名'
          }
        ]" />
      <van-field class="setBorder" v-model="form.wechat" :border="false" label="微信号：" placeholder="请填写您的微信ID，方便确认打款信息"
        :rules="[
          {
            required: true,
            message: '微信号不合法'
          }
        ]" />
      <van-field class="setBorder" v-model="form.idnumber" :border="false" label="身份证号：" placeholder="请填写您的身份证号" :rules="[
          {
            required: true,
            validator: sfzTest,
            message: '请正确填写您的身份证号'
          }
        ]" />
      <van-field name="uploader" label="身份证照片：" :rules="[
          {
            required: true,
            validator: imgTest,
            message: '请正确上传您的身份证照片'
          }
        ]">
        <template #input>
          <div>
            <van-uploader v-model="certificatePhoto" @oversize="onOversize" :max-size="5000 * 1024" :max-count="1"
              :multiple='false' accept="image/*" :after-read="afterRead1" />
            <div class="imgTitle">正面</div>
          </div>
          <div>
            <van-uploader v-model="certificateBackPhoto" @oversize="onOversize" :max-size="5000 * 1024" :max-count="1"
              :multiple='false' accept="image/*" :after-read="afterRead2" />
            <div class="imgTitle">反面</div>
          </div>
        </template>
      </van-field>
      <van-field class="setBorder" :border="false" label="支付方式：">
        <template #input>
          <van-radio-group v-model="form.paymentMethod" @change="clearForm" direction="horizontal">
            <van-radio checked-color="#fe8154" :name="10">银行卡</van-radio>
            <van-radio checked-color="#fe8154" :name="20">支付宝</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field v-if="form.paymentMethod == '20'" class="setBorder" v-model="form.alipayNo" :border="false"
        label="支付宝帐号：" placeholder="请正确填写您的支付宝帐号" :rules="[
          {
            required: true,
            message: '请填写您的支付宝帐号'
          }
        ]" />
      <!--  银行卡 -->
      <div v-if="form.paymentMethod == '10'">
        <van-field class="setBorder" v-model="form.cardholderBank" :border="false" label="持卡银行：" placeholder="请填写您的持卡银行"
          :rules="[
            {
              required: true,
              message: '请填写您的持卡银行'
            }
          ]" />
        <van-field readonly clickable :border="false" v-model="form.address" class="setBorder setLabelWidth"
          label="开户行所在地：" placeholder="点击选择城市" @click="show = true" :rules="[
            {
              required: true,
              message: '请选择您的开户行所在地'
            }
          ]" />
        <van-field class="setBorder" :border="false" label="开户行：" v-model="form.openBank" placeholder="请填写您的开户行" :rules="[
            {
              required: true,
              message: '请填写您的开户行'
            }
          ]" />
        <van-field class="setBorder" v-model="form.bankCardNo" :border="false" type="tel" label="银行卡号："
          placeholder="请填写您的银行卡号" :rules="[
            {
              required: true,
              message: '请填写您的银行卡号'
            }
          ]" />
      </div>
      <van-button class="submit" round block type="info" native-type="submit">提交
      </van-button>
      <div class="box-list">
        <div class="box-list-title">
          温馨提示
        </div>
        <van-divider>
          以下人员不可作为KOC收款信息人
        </van-divider>
        <div class="box-list-tip"></div>
        <div class="box-list-row">
          1. 军人、公务员；
        </div>
        <div class="box-list-row">
          2. 非中国大陆户籍；
        </div>
        <div class="box-list-row">
          3. 企业注册的法人、股东、董监；
        </div>
        <div class="box-list-row">
          4.执业律师、在编教师、注册测绘师、证券行业、财会行业、注册拍卖、注册验船、专利代理等业务中，所涉及到的业务需要行业从业资格证书或证明的工作；
        </div>
        <div class="box-list-row">
          5. 建筑行业中的农民工；
        </div>
        <div class="box-list-row">
          6. 导演、演职人员、记者、播音员主持人等的极高劳务收入所得者；
        </div>
        <div class="box-list-row">
          7. 保险代理行业中的保险代理人；
        </div>
        <div class="box-list-row">
          8. 建筑建安中的农民工；
        </div>
        <div class="box-list-row">
          9. 医疗行业中的医疗销售类型人员；
        </div>
        <div class="box-list-row">
          10. 未满18岁人员
        </div>
      </div>
    </van-form>
    <van-popup v-model:show="show" position="bottom">
      <van-area title="开户行所在地" :columns-num="2" :area-list="areaList" @confirm="selectAddress" @cancel="show = false" />
    </van-popup>
  </div>
  <reading ref="reading" />
</template>

<script>
import { getUserInfo } from '@/api/information.js'
import { areaList } from '@vant/area-data'
import { uploadImage } from '@/api/public.js'
import { Toast } from 'vant'
import { submitPay } from '@/api/profit.js'
import reading from './component/reading.vue'
export default {
  components: { reading },
  data() {
    return {
      form: {
        name: '',
        wechat: '',
        idnumber: '',
        paymentMethod: '',
        cardholderBank: '',
        openBank: '',
        alipayNo: '',
        bankCardNo: '',
        address: '',
        openBankProvince: '',
        openBankCity: '',
        certificatePhoto: '',
        certificateBackPhoto: ''
      },
      certificatePhoto: [],
      certificateBackPhoto: [],
      areaList,
      show: false,
      userInfo: {}
    }
  },
  async mounted() {
    let userInfo = await getUserInfo()
    if (!userInfo.payment_method || userInfo.apply_settlement === 0) {
      this.$refs.reading.showPopup()
      this.form.paymentMethod = 10
    } else {
      this.form.paymentMethod = userInfo.payment_method
    }
    if (userInfo) {
      this.$nextTick(() => {
        this.certificatePhoto = userInfo.certificate_photo
          ? [{ url: userInfo.certificate_photo }]
          : []
        this.certificateBackPhoto = userInfo.certificate_back_photo
          ? [{ url: userInfo.certificate_back_photo }]
          : []
        this.form.certificateBackPhoto = userInfo.certificate_back_photo
        this.form.certificatePhoto = userInfo.certificate_photo
        this.form.name = userInfo.name
        this.form.wechat = userInfo.wechat
        this.form.idnumber = userInfo.idnumber
        this.form.alipayNo = userInfo.alipay_no
        this.form.cardholderBank = userInfo.cardholder_bank
        this.form.openBank = userInfo.open_bank
        this.form.bankCardNo = userInfo.bank_card_no
        this.form.openBankProvince = userInfo.open_bank_province
        this.form.openBankCity = userInfo.open_bank_city
        this.form.address = userInfo.open_bank_province
          ? `${userInfo.open_bank_province}/${userInfo.open_bank_city}`
          : ''
        this.userInfo = userInfo
      })
    }
  },
  methods: {
    // 图片校验
    onOversize(file) {
      Toast('图片大小不能超过 5Mb')
    },
    // 图片上传
    afterRead1(file) {
      let formData = new FormData()
      formData.append('images', file.file)
      uploadImage(formData).then(res => {
        this.certificatePhoto = [res]
        this.form.certificatePhoto = res.url
      })
    },
    afterRead2(file) {
      let formData = new FormData()
      formData.append('images', file.file)
      uploadImage(formData).then(res => {
        this.certificateBackPhoto = [res]
        this.form.certificateBackPhoto = res.url
      })
    },
    imgTest() {
      return this.certificatePhoto.length > 0 &&
        this.certificateBackPhoto.length > 0 && !!this.form.certificateBackPhoto != '' && !!this.form.certificatePhoto != ''
    },
    sfzTest(id) {
      // 1 "验证通过!", 0 //校验不通过
      var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
      //号码规则校验
      if (!format.test(id)) {
        return false
      }
      //区位码校验
      //出生年月日校验   前正则限制起始年份为1900;
      var year = id.substr(6, 4),//身份证年
        month = id.substr(10, 2),//身份证月
        date = id.substr(12, 2),//身份证日
        time = Date.parse(month + '-' + date + '-' + year),//身份证日期时间戳date
        now_time = Date.parse(new Date()),//当前时间戳
        dates = (new Date(year, month, 0)).getDate();//身份证当月天数
      if (time > now_time || date > dates) {
        return false
      }
      //校验码判断
      var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);   //系数
      var b = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');  //校验码对照表
      var id_array = id.split("");
      var sum = 0;
      for (var k = 0; k < 17; k++) {
        sum += parseInt(id_array[k]) * parseInt(c[k]);
      }
      if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
        return false
      }
      return true
    },
    clearForm(val) {
      // 暂时不用重置
      /*   this.form.address = ''
      this.form.alipayNo = ''
      this.form.cardholderBank = ''
      this.form.openBank = ''
      this.form.bankCardNo = ''
      this.$refs.form.resetValidation([
        'alipayNo',
        'cardholderBank',
        'address',
        'openBank',
        'bankCardNo'
      ]) */
    },
    async onSubmit() {
      await submitPay(this.form)
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        icon: 'success',
        message: '提交成功'
      })
      let second = 2
      const timer = setInterval(() => {
        second--
        if (second == 0) {
          clearInterval(timer)
          Toast.clear()
          this.$store.commit('remove', 'extension')
          this.$router.back()
        }
      }, 1000)
    },
    selectAddress(val) {
      this.form.openBankProvince = val[0].name
      this.form.openBankCity = val[1].name
      this.form.address = `${val[0].name}/${val[1].name}`
      this.show = false
    }
  }
}
</script>

<style scoped lang="less">
.withdrawal {
  min-height: 100vh;
  padding: 40px 20px;
  background: white;
  .tipsBox {
    .tipsBox-title {
      text-align: center;
      color: rgb(250, 27, 27);
      font-size: 28px;
      span {
        font-weight: bold;
      }
    }
    .tipsBox-content-list {
      text-align: left;
      font-size: 26px;
      .tipsBox-conent-row {
        margin-bottom: 8px;
      }
    }
    .tips-btn {
      margin-top: 16px;
      display: inline-block;
      padding: 10px 20px;
      border-radius: 14px;
      font-size: 24px;
      letter-spacing: 2px;
      background: rgb(117, 204, 238);
      color: white;
    }
  }
  .setBorder {
    border-bottom: 1px solid rgb(238, 236, 236);
    /deep/ .van-field__label {
      margin-right: 0px;
    }
  }
  .submit {
    width: 90%;
    margin: 30px auto 0px;
    background: #fe774c;
    color: white;
    border: none;
    letter-spacing: 4px;
    border-radius: 50px;
    font-size: 34px;
  }
  .setLabelWidth {
    /deep/ .van-field__label {
      width: 200px;
    }
  }
  .box-list {
    margin-top: 30px;
    padding: 20px 30px;
    text-align: left;
    .box-list-title {
      text-align: center;
      font-weight: bold;
      font-size: 34px;
    }
    .box-list-row {
      margin-bottom: 8px;
      color: #666666;
      text-align: justify;
      line-height: 44px;
      font-size: 28px;
    }
  }
  .imgTitle {
    font-size: 16px;
  }
}
</style>
