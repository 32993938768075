import { request } from './index'

/**
 * 获取收益汇总
 */
export function getSummary(data) {
  return request({
    method: 'post',
    url: '/user/settlementDataCount',
    data
  })
}
/**
 * 获取收益明细
 */
export function getProfitDetailed(data) {
  return request({
    method: 'post',
    url: '/user/settlementData',
    data
  })
}
/**
 * 获取知乎收益明细
 */
export function getzhihuProfitDetailed(data) {
  return request({
    method: 'post',
    url: '/user/zhihuData',
    data
  })
}

/**
 * 编辑收款方式
 */

export function submitPay(data) {
  return request({
    method: 'post',
    url: '/user/updatePaymentMethod',
    data
  })
}
/**
 * 提交提现申请
 */
export function submitWithdrawa(data) {
  return request({
    method: 'post',
    url: '/user/applySettlementData',
    data
  })
}

/**
 * 获取提现记录
 */
export function getwithdrawaLog(data) {
  return request({
    method: 'post',
    url: '/user/applySettlementDataLog',
    data
  })
}
