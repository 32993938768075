<template>
  <van-popup :lock-scroll="true" :show="overlayShowFlag"
    class-name="OverlayStyle">
    <div class="box">
      <div class="box-title">
        温馨提示
      </div>
      <div class="box-tips">
        以下人员不可作为KOC收款信息人
      </div>
      <div class="box-list">
        <div class="box-list-row">
          1. 军人、公务员；
        </div>
        <div class="box-list-row">
          2. 非中国大陆户籍；
        </div>
        <div class="box-list-row">
          3. 企业注册的法人、股东、董监；
        </div>
        <div class="box-list-row">
          4.执业律师、在编教师、注册测绘师、证券行业、财会行业、注册拍卖、注册验船、专利代理等业务中，所涉及到的业务需要行业从业资格证书或证明的工作；
        </div>
        <div class="box-list-row">
          5. 建筑行业中的农民工；
        </div>
        <div class="box-list-row">
          6. 导演、演职人员、记者、播音员主持人等的极高劳务收入所得者；
        </div>
        <div class="box-list-row">
          7. 保险代理行业中的保险代理人；
        </div>
        <div class="box-list-row">
          8. 建筑建安中的农民工；
        </div>
        <div class="box-list-row">
          9. 医疗行业中的医疗销售类型人员；
        </div>
        <div class="box-list-row">
          10. 未满18岁人员
        </div>
      </div>
      <van-button type="primary" class="box-submit" size="small"
        @click="overlayShowFlag=false">我已阅读</van-button>
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      overlayShowFlag: false
    }
  },
  methods: {
    showPopup() {
      this.overlayShowFlag = true
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  width: 622px;
  border-radius: 18px;
  padding: 30px 40px;
  background-color: #fff;
  .box-title {
    font-size: 36px;
    font-weight: bold;
  }
  .box-tips {
    color: #999999;
    font-size: 28px;
    padding: 6px 0px;
  }
  .box-list {
    margin-top: 34px;
    text-align: left;
    .box-list-row {
      margin-bottom: 8px;
      color: #666666;
      text-align: justify;
      line-height: 44px;
      font-size: 28px;
    }
  }
  .box-submit {
    margin: 20px auto 0px;
    width: 526px;
    background: #fe774c;
    color: white;
    font-size: 30px;
    border: none;
    letter-spacing: 2px;
    border-radius: 6px;
  }
}
</style>
